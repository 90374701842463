<template>
  <div>
    <AddHeaderTitle :Header="Header"/>
    <AppHeaderInfo :infos="infos" />
    <b-card ref="formContainer">
      <b-table
      :fields="fields"
      :items="mails.datas"
      :small="small"
      >
        <!-- Column: key -->
        <template #cell(key)="data">
					<b-badge variant="primary">
						{{ data.item.key }}
					</b-badge>
				</template>
        <!-- Column: precent -->
        <template #cell(t_pct)="data">
          <b-row>
            <b-col md="10">
              <b-progress
                height="15px"
                :value="data.item.t_pct"
                max="100"
                show-value
                variant="success"
                class="progress-bar-success"
                striped
              />
            </b-col>   
          </b-row>    
        </template>
        <!-- Column: Actions -->
        <template #cell(action)="data">
					<b-row>
						<b-col>
							<b-button variant="flat-primary" @click="viewDetails(data.item.key)" size="sm">
								<feather-icon icon="SearchIcon" />
								details
							</b-button>
						</b-col>	
					</b-row>
				</template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { BTable, BCard, BRow, BCol, BButton, BProgress, BBadge} from 'bootstrap-vue';
import AddHeaderTitle from '@/app/views/components/AppHeaderTitle.vue';
import AppHeaderInfo from "@/app/views/components/AppHeaderInfo.vue";
import  { Mail } from '@/mixins/mail.js'

export default {
  components: {
    BTable,
    BCard,
    AddHeaderTitle,
    BRow, 
    BCol, 
    BButton,
    BProgress,
    BBadge,
    AppHeaderInfo
  },
  mixins: [ Mail ],
  data() {
    return {
      small: true,
      mails: [],
      fields : [
        { key: 'key', label: 'KEY' },
        { key: 'info', label: 'INFORMATION' },
        { key: 't_pct', label: 'POURCENTAGE DE TRADUCTION' },
        'ACTION'
      ], 
      Header: {
        config: "configuration",
				listLink: "",
				list: "email",
			},
      infos: {
				title: "Information",
				content: "texte explicatif de la page",
			},
    }
  },
  async mounted() {
    await this.loadMails();
  },
  methods: {
    viewDetails(key) {
      this.$router.push({ path: `/configuration/mail/list/${key}` })
    },
    async loadMails() {
      let loader = this.$loading.show({
      container: this.$refs.formContainer,
      canCancel: true,
				});
      try {
        const datas = await this.getAll()
        if (datas.status === 1 ) {
          this.mails = datas
        } else {
          this.$sweetError("AF-48");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-48");
      }
    }
  },
}
</script>
