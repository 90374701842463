<template>

	<b-card
		:title="infos.title" 
		style="min-height: 150px; max-width:150rem"
		:img-src='"@/assets/images/banner/banner.png"'
		overlay
		img-height="150rem"
	>	
		<b-card-text>
			{{ infos.content }}
		</b-card-text>
	</b-card>

</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCardText, BCard} from "bootstrap-vue";

export default {
	components: {
		BCardText,
		BCard,
	},
	data() {
		return {
			backGround : require('@/assets/images/banner/banner.png')
		};
	},
	props: {
		infos: {
			type: Object,
		},
		icon: {
			type: String,
			default: "",
		},
	},
	methods: {},
};
</script>
