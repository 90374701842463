import {api} from '@/services/axios'

export const Mail  = {
  methods: {
    // API get all mail object
    async getAll() {
      try {
        const response = await api.get('getter/infos/mail')
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    // get all translation of a mail by key 
    async getTransList(key) {
      try {
        const response = await api.get(`getter/infos/mail/${key}`)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    // update mail detail by key and lang 
    async editMailDetail(key, lang, values) {
      try {
        const values_formatted = {"values": {
          "subject": values.subject,
          "name": values.name,
          "content": values.content
        }}
        const response = await api.put(`setter/mail/${key}/${lang}`, values_formatted)
        console.log('test APi ', response)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
  } 
}

